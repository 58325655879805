
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import { MapContainer, TileLayer, Marker, Popup } from '@monsonjeremy/react-leaflet'

import Icon from '@mui/material/Icon';


function what_icon(d){


  if(d.order_progress=="Out for delivery"){
      return <LocalShippingIcon sx={{ fontSize: 100 }} color="warning" />
  }

  if(d.order_progress=="Delivery Confirmed"){
      return   <CheckCircleIcon sx={{ fontSize: 100 }} color="success"/>
  }

  if(d.order_progress=="Picking"){
      return <AccessibilityIcon sx={{ fontSize: 100 }} color="info"/>
    }

  if(d.order_progress=="Picking Complete"){
      return <AccessibilityIcon sx={{ fontSize: 100 }} color="success"/>
    }



}


function show_icon(icon,col){
  if (col==undefined)
    col="error"


    return <Icon color={col} sx={{ fontSize: 100 }}>{icon}</Icon>



}



function text_state(d){
  return d.order_progress
}

function extract_image(data){
  let img_array=[]
  let r=[]
  try{
  if(data.length>0){
    img_array=data
    img_array=img_array.replaceAll("[","")
    img_array=img_array.replaceAll("]","")
    img_array=img_array.replaceAll("'","")
    img_array=img_array.replaceAll(" ","")
    img_array=img_array.split(",")
    for(let i=0;i<img_array.length;i++){
      r.push(<img style={{maxWidth:"400px"}} src={"https://fhb-server.s3.eu-west-2.amazonaws.com/"+img_array[i]} ></img>)
    }
    return r
}
}catch(err){}
  return []
}


export default function TrackSummary(props){
  let d=props.data

  let a=0;
  let show_map=false
  let delimage=[]
  try{
  delimage=extract_image(d.rod_images)
  }
  catch(err){}

  if("sum" in d){

    if(d.delpos.length==2){
      show_map=true
    }

    return (
      <div key={"order_"+props.index}>


      {show_map ? (
      <div className="map_area">
      <MapContainer center={d.delpos} zoom={13} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={d.delpos}>
          <Popup>
            Delivery Area<br />
          </Popup>
        </Marker>
      </MapContainer>

      </div>):("")}
            <div className="order_sum">
            <center >
              <Typography variant="h5" gutterBottom component="div" title={"Customer ref:"+d.cust_ref}>
              Order&nbsp;:&nbsp;<b>{d.order_ref}</b>
              </Typography>


              {show_icon(d.sum.icon,d.sum.icon_col)}
              <Typography variant="caption" gutterBottom component="div" title={"Customer ref:"+d.cust_ref}>
              ({d.cust_ref})
              </Typography>
              <Typography variant="subtitle1" display="block" gutterBottom>
                {d.sum.subtext1}
              </Typography>

              <Typography variant="subtitle1" display="block" gutterBottom>
                {d.sum.subtext2}
              </Typography>

              {delimage}



            </center>





            </div>


      </div>
    )


  }





  return (<div key={"order_"+props.index}>

            <center>
            <Typography variant="h5" gutterBottom component="div">
            Order:<b>{d.order_ref}</b>
            </Typography>
            {what_icon(d)}
            <Typography variant="subtitle1" display="block" gutterBottom>
            {text_state(d)}
            </Typography>


            </center>

  <code style={{whiteSpace: "pre-wrap"}}>
  {JSON.stringify(d,null,'\t')}
  </code>


  </div>)

}
