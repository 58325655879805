
import './App.css';
import React, { Component } from "react";
import Button from '@mui/material/Button';
import TrackSummary from "./TrackSummary.js"
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import RestoreIcon from '@mui/icons-material/Restore';
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

var server=""
if(window.location.host=="localhost:3001"){
  server="http://localhost:8080"
}


function addUrlParameter(name, value) {
    const url = new URL(window.location.href);
url.searchParams.set(name, value);
url.searchParams.delete('param2');
window.history.replaceState(null, null, url); // or pushState
}

let track_id = new URL(window.location.href).searchParams.get('track')
if(track_id==null)
  track_id=""




let branch=[
        {
            code:"01",
            name:"Rainham",
            tel:"01708253545",
            addr:"24-36 Lamson Road, Ferry Lane North, Rainham, Essex, ",
            postcode:"RM13 9YY"
        },
        {
            code:"02",
            name:"Ilkeston",
            tel:"01159302070",
            addr:"Condor Road, Quarry Hill Industrial Estate, Ilkeston, Derbyshire, ",
            postcode:"DE7 4RE"
        },
        {
            code:"03",
            name:"Southampton",
            tel:"02380703333",
            addr:"502 Millbrook Road, Third Avenue, Millbrook, Southampton, ",
            postcode:"SO15 0JX"
        },
        {
            code:"04",
            name:"Haydock",
            tel:"01942868888",
            addr:"Unit 8 Haydock Lane, Haydock Industrial Estate, Haydock, St Helens, ",
            postcode:"WA11 9XE"
        },
        {
            code:"05",
            name:"Birmingham",
            tel:"01215658282",
            addr:"81-82 Middlemore Industrial Estate, Middlemore Road, Smethwick, Birmingham, ",
            postcode:"B66 2EP"
        },
        {
            code:"07",
            name:"Glasgow",
            tel:"01417736699",
            addr:"Dunivaig Road, Queenslie Industrial Estate, Queenslie, Glasgow, Scotland, ",
            postcode:"G33 4TP"
        }
]


function branch_info(track_id){
  let id="01"
  let valid=["01","02","03","04","05","07"]
  if(track_id!=null)
    if(track_id.length>=2 ){
      id=track_id.substring(0,2)
      if(valid.includes(id)==false){
        id="00"
      }
    }
  let r=[(<span><b>Rainham</b>    <span>Tel : <b><a href="tel:01708253545">01708253545</a></b></span></span>),(<br/>),(<span>24-36 Lamson Road, Ferry Lane North, Rainham, Essex, <b>RM13 9YY</b></span>),(<br/>)]

  branch.forEach((b, i) => {

    if(b.code==id){

      r=[(<span><b>{b.name}</b>    <span>Tel : <b><a href={"tel:"+b.tel}>{b.tel}</a></b></span></span>),(<br/>),(<span>{b.addr}<b>{b.postcode}</b></span>),(<br/>)]
    }
  });





  return r
}

//var searchParams = new URLSearchParams(window.location.search)
//  searchParams.set(name, value)
//  window.location.search = searchParams.toString()

export default class App extends Component {
  constructor(props) {
    super(props);
    let track_ref_stage=0
    let tracknum=""

    if(window.location.pathname.startsWith("/track/")==true){
        track_ref_stage=5;
        tracknum=window.location.pathname.split("/track/")[1]
      }
      if(track_id!="" ){

        track_ref_stage=5;
        tracknum=track_id
      }

      this.state = {
        data:[],
        tracknum:tracknum,
        postcode:"",
        loadingData:false,
        snack_open:false,
        snack_message:"...",
        track_ref_stage:track_ref_stage
      };
  }
 handleChange = event => {

  };
  async componentDidMount() {
    console.log(window.location.pathname)
    if(this.state.track_ref_stage!=0){
      await this.checkref(this.state.tracknum)
    }

 }
   getTable= async()=>{

   }

track_submit=async(e)=>{
  e.preventDefault()
  if(this.state.tracknum.length<6){
    this.setState({snack_message:"Tracking number is not long enough",snack_open:true})
    return;
  }else{
    this.setState({track_ref_stage:1})
    await this.checkref(this.state.tracknum)
  }
}

postcode_submit=async(e)=>{
  e.preventDefault()
  if(this.state.postcode.length<6){
    this.setState({snack_message:"Postcode should be longer",snack_open:true})
    return;
  }else{
    this.setState({track_ref_stage:3})

    //setTimeout(() => {
    //this.reference_response({postcode_ref:true})
    //}, 1000);
    await this.checkref(this.state.tracknum,this.state.postcode)
  }




}



getPostcode2latlon=async(postcode)=>{


  let response=await fetch("https://api.postcodes.io/postcodes/"+postcode)



  let data = await response.json(0)
  console.log(data)
  if(data.status==200){
    return [data.result.latitude,data.result.longitude]
  }

  return []
}

checkref=async(ref,postcode="")=>{
  const response = await fetch(server+"/trackapi?cmd=trackrequest&ref="+ref+"&postcode="+postcode)



  const data = await response.json();
  await this.reference_response(data)
}


reference_response=async (r)=>{
  console.log(r)



  if(r.valid==true){
    if("data" in r){

      if(r.data[0].track_id==null){
        this.setState({track_ref_stage:0})
        this.setState({snack_message:"Not recognised as a tracking reference",snack_open:true})
        return
    }



      if(r.data[0].postcode==r.data[0].delpos){
        let x=await this.getPostcode2latlon(r.data[0].postcode)
        r.data[0].delpos=x
      }


      this.setState({data:r.data})
    }

    try{
      if(window.location.pathname.startsWith("/track/")==false){
        addUrlParameter("track",r.data[0].track_id)
      }
    }catch(err){}

    try{
      this.setState({tracknum:r.data[0].track_id})
    }catch(err){}


    this.setState({track_ref_stage:6})
    return
  }

  if(r.valid==false){
    if(this.state.track_ref_stage==1){
      //It was a submission that could be an order ref
      if(this.state.tracknum.length<8){
        this.setState({track_ref_stage:2})
        return
      }
    }
    //Reset
    this.setState({track_ref_stage:0})
    this.setState({snack_message:"Not recognised as a tracking reference",snack_open:true})
  }


}

update_field_postcode=(e)=>{
  this.setState({postcode:e.target.value})
}

update_field_track=(e)=>{
  this.setState({tracknum:e.target.value})
}

handelSnackClose=(event, reason)=>{
 this.setState({snack_open:false});
}

  render() {
  //<CircularProgress className={10} />
    return (
      <div className="App">
      <div>
{this.state.track_ref_stage<6 ? (
      <Paper  sx={{height:"100px"}} elevation={2}>
        <div className="center_align" style={{paddingTop:"35px",height:"100px"}} >
            <Typography variant="subtitle1" gutterBottom component="div">
                    {this.state.track_ref_stage==0 ? (
                                                        <span>
                                                          Please enter a tracking or order reference</span>
                                                      ):("")}

                    {this.state.track_ref_stage==1 ? (<span>Checking reference...
                                                        <br/><br/> <br/><br/>    <CircularProgress /></span>
                                                      ):("")}

                     {this.state.track_ref_stage==2 ? (


                                                      <span st><IconButton aria-label="back" onClick={()=>{this.setState({track_ref_stage:0})}} >
                                                      <ArrowBackIcon />
                                                      </IconButton>
                                                      Please enter delivery postcode</span>
                                                      ):("")}


                    {this.state.track_ref_stage==3 ? (
                                                        <span>Validating Postcode...
                                                      <br/><br/> <br/><br/> <CircularProgress /></span>
                                                      ):("")}

                     {this.state.track_ref_stage==5 ? (
                                                        <span>Loading Tracking info...
                                                        <br/>
                                                        <i>{this.state.tracknum}</i>
                                                        <br/>
                                                        <br/><CircularProgress />
                                                        <br/>
                                                        <Button  onClick={()=>{this.setState({track_ref_stage:0})}} >Track another order</Button>
                                                        <br/><br/></span>
                                                        ):("")}
                  </Typography>
        </div>
</Paper>):("")}
        <div>

                      {this.state.track_ref_stage==6 ? (<div>

                        {this.state.data.map((d,d_index)=><TrackSummary data={d} index={d_index}/>)}
                        <center><Button  onClick={()=>{this.setState({track_ref_stage:0})}} >Search another</Button></center>
                        <br/><br/>
                        </div>):("")}

        </div>



        {this.state.track_ref_stage==2 ?
        (<Box
          className="center_align"
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            onSubmit={this.postcode_submit}
            autoComplete="off"
          >
          <TextField id="standard-basic" label="Delivery postcode" variant="standard" onChange={this.update_field_postcode}/>
          <br/>  <Button variant="outlined" onClick={this.postcode_submit}>Verify</Button>
        </Box>):("")}


        {this.state.track_ref_stage==0 ?
        (<Box
          className="center_align"
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            onSubmit={this.track_submit}
            autoComplete="off"
          >
          <TextField id="standard-basic" label="Tracking Num" variant="standard" type="number" onChange={this.update_field_track}/>
      <br/>    <Button variant="outlined" onClick={this.track_submit}>Track Order</Button>
        </Box>):("")}
        </div>



        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.state.snack_open}
          autoHideDuration={6000}
          onClose={this.handelSnackClose}
          message={this.state.snack_message}
          key={"tracking_feedback"}
     />


     <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 ,height:"100px"}} elevation={2}>


     <BottomNavigation>
          <div className="footer">
            <a href="https://www.fhbrundle.co.uk/"><img src={"/logo.jpg"} height="96px"></img></a>


            <div className="place">
              <div className="place_icon">
                <center><Icon color="info" sx={{ fontSize: 60 }}>place</Icon></center>
              </div>
              <div className="place_addr">
                  {branch_info(this.state.tracknum)}
              </div>
            </div>
        </div>
     </BottomNavigation>
      </Paper>
      </div>


    );
  }
}
